import React from "react"
import { Picture } from "react-responsive-picture"

export const ShopOnlineBanner = ({ _query }) => {
  let banner
  _query.data.body.map(element => {
    if (element.__typename === "PrismicShopOnlinePageBodyBanner") {
      return banner = element.primary
    }else return null
  })

  return (
    <div className="so-banner">
      <div className="so-banner__bg">
        <Picture
          className="so-banner__img"
          sources={[
            {
              srcSet: banner.banner__image.xs.url,
              media: "(max-width: 767px)",
            },
            {
              srcSet: banner.banner__image.sm.url,
              media: "(max-width: 1023px)",
            },
            {
              srcSet: banner.banner__image.md.url,
              media: "(max-width: 1280px)",
            },
            {
              srcSet: banner.banner__image.lg.url,
              media: "(max-width: 1366px)",
            },
            {
              srcSet: banner.banner__image.url,
              media: "(min-width: 1367px)",
            },
          ]}
        />
      </div>
      <div className="so-banner__header">
        <h1 className="so-banner__pagename">{_query.data.title.text}</h1>
        <h2 className="so-banner__title">{banner.banner_title.text}</h2>
        <p className="so-banner__description">
          {banner.banner_description.text}
        </p>
      </div>
    </div>
  )
}
