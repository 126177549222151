import React from "react"

export const ShopOnlineStorytelling = ({ _query }) => {
  let storytelling

  _query.data.body.map(element => {
    if (element.__typename === "PrismicShopOnlinePageBodyStorytelling") {
      return  storytelling = element.primary
    }else return(null)
  })

  return (
    <div className="so-storytelling">
      <div
        className="so-storytelling__text"
        dangerouslySetInnerHTML={{
          __html: storytelling.storytelling_description.html,
        }}
      />
    </div>
  )
}
