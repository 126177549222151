import React from "react"
import { Picture } from "react-responsive-picture"

export const ShopOnlineShops = ({ _query }) => {
  let shops
  let zone
  const data = _query.data.body

  //   _query.data.body.map(element => {
  //     if (element.__typename === "PrismicShopOnlinePageBodyShops") {
  //       shops = element.items
  //       zone = element.primary
  //     }
  //   })

  return (
    <div className="shop_zone">
      <div className="shop_zone_title">
        {
          data.map((item, index) => {
          if (item.__typename === "PrismicShopOnlinePageBodyShops") {
            zone = item.primary
            shops = item.items
            return (
              <div key={index}>
                <p>{zone.region_name.text}</p>
                <div className="so-shops">
                  {shops.map((shop, key) => {
                    return (
                      <a
                        key={key}
                        className="so-shops__item"
                        data-label={shop.shop_name.text}
                        href={shop.shop_url.text}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Picture
                          className="so-shops__item-image"
                          src={shop.shop_image.url}
                          alt={shop.shop_image.alt}
                        />
                      </a>
                    )
                  })}
                </div>
              </div>
            )
          } else return null
        })}
      </div>
    </div>
  )
}
