import React from "react"
import Layout from "../components/layout"
import { usePrismShopOnline } from "../hooks/use_prism_shop_online"
import { ShopOnlineBanner } from "../content/shop_online/banner/shop_online_banner"
import { ShopOnlineStorytelling } from "../content/shop_online/storytelling/shop_online_storytelling"
import { ShopOnlineShops } from "../content/shop_online/shops/shop_online_shops"
import "../styles/pages/shop_online/shop_online.scss"

const ShopOnline = () => {
  const shopOnlineQuery = usePrismShopOnline()

  return (
    <Layout>
      <ShopOnlineBanner _query={shopOnlineQuery} />
      <ShopOnlineStorytelling _query={shopOnlineQuery} />
      <ShopOnlineShops _query={shopOnlineQuery} />
    </Layout>
  )
}

export default ShopOnline
