import { useStaticQuery, graphql } from "gatsby"

export const usePrismShopOnline = () => {
  const prismic = useStaticQuery(
    graphql`
      query {
        prismicShopOnlinePage {
          data {
            title {
              text
            }
            body {
              __typename
              ... on PrismicShopOnlinePageBodyBanner {
                primary {
                  banner__image {
                    url
                    lg {
                      url
                    }
                    md {
                      url
                    }
                    sm {
                      url
                    }
                    xs {
                      url
                    }
                  }
                  banner_title {
                    text
                  }
                  banner_description {
                    text
                  }
                }
              }
              __typename
              ... on PrismicShopOnlinePageBodyStorytelling {
                primary {
                  storytelling_description {
                    html
                  }
                }
              }
              __typename
              ... on PrismicShopOnlinePageBodyShops {
                primary {
                  region_name {
                    text
                  }
                }
                items {
                  shop_url {
                    text
                  }
                  shop_image {
                    url
                    alt
                  }
                  shop_name {
                    text
                    html
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return prismic.prismicShopOnlinePage
}
